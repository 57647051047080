
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Admin } from '@/modules/admin'
import { ICompanyFilter, ICompanySearch } from '../types';
import { ICompany, ITableHeader, IPagination } from '../../system/types';

@Component
export default class CompanyView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  admin = $app.module(Admin);

  loading = false;

  filter: ICompanyFilter = $app.clone(this.admin.$store.CompanySearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: ICompany | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Name'),
        value: 'RoleName',
        align: 'left',
        width: '70%'
      },
      {
        text: $app.i18n('admin.system.CompanyEdit.CompanyCountry'),
        value: 'CompanyCountry',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('system.Code'),
        value: 'CompanyCode',
        align: 'left',
        width: '20%'
      }
    ];
  }

  get search(): ICompanySearch {
    return this.admin.$store.CompanySearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.admin.$store.findCompany(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.admin.$store.CompanySearch, this.filter);
    this.admin.$store.setCompanySearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: ICompany) {
    this.processItem = true;
    try {
      await this.admin.$store.storeCompany(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: ICompany) {
    this.processItem = true;
    try {
      await this.admin.$store.removeCompany(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: ICompany) {
    this.selectedItem = $app.clone(item);
    this.showItem = true;
  }

  append() {
    this.selectedItem = {
      id: null,
      name: '',
      processing: false,
      code: null,
      descr: null,
      country: 'LV',
      address: '',
      phone: '',
      email: '',
      nvdCode: null,
      nvdSubCode: null,
      nvdPayer: null,
      clinics: [],
      services: []
    };
    this.showItem = true;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
